import React, { useState, useEffect } from "react";
import { navigate, Link } from "gatsby"
import styled from "styled-components";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Img from "gatsby-image";
import { useMediaQuery } from 'react-responsive';
import { useForm } from "react-hook-form";
import axios from "axios";

import HvezdaImg from "../assets/img/hvezdaImg.svg";
import FirmyImg from "../assets/img/firmyczLogo.png";
import MapsImg from "../assets/img/mapsLogo.png";
import DetiImg from "../assets/img/zivotDetemLogo.png";

const ZpetnaVazbaInner = styled.div`
  position: relative;
  background: #FBFBFA;
  padding-bottom: 100px;

  .hvezdicky {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
    margin: 0 auto;

    button {
      background: none;
      border: 0;
      padding: 0;
      cursor: pointer;

      img {
        display: block;
        filter: grayscale();

        &:hover {
          filter: grayscale() brightness(0.9);
        }
      }

      .isActive {
        filter: none;

        &:hover {
          filter: brightness(0.9);
        }
      }
    }
  }

  .pozadi {
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100vw;
    height: 100vh;
  }

  .topSection {
    display: block;
    margin: 0px auto;
    padding: 70px 0px;
    width: calc(100% - 100px);
    max-width: 820px;
    position: relative;
    z-index: 1;

    @media (max-width: 600px) {
      padding: 40px 0px;
      width: calc(100% - 50px);
    }

    h1 {
      margin-bottom: 30px;
      font-family: Ivar;
      font-size: 40px;
      color: #000000;
      text-align: center;
      margin-top: 0;

      @media (max-width: 600px) {
        font-size: 28px;
      }
    }

    p {
      font-family: Visuelt-Regular;
      font-size: 16px;
      color: #000000;
      text-align: center;
      line-height: 25px;
    }
  }

  .whiteBox {
    background: #FFFFFF;
    box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
    display: block;
    width: calc(100% - 100px - 100px);
    max-width: 720px;
    border-radius: 5px;
    margin: 0 auto;
    padding: 50px;
    position: relative;
    z-index: 1;

    @media (max-width: 600px) {
      padding: 30px 20px;
      width: calc(100% - 50px - 20px);
    }

    form {
      max-width: 550px;
      width: 100%;
      margin: 0 auto;
    }

    label {
      margin-bottom: 50px;
      display: block;

      span {
        font-family: Visuelt-Medium;
        font-size: 18px;
        color: #000000;
        text-align: center;
        display: block;
        margin-bottom: 20px;
      }

      p {
        margin-top: -10px;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #919191;
        text-align: center;
        margin-bottom: 25px;
      }

      input {
        display: block;
        background: #FFFFFF;
        border: 1px solid #E2E2E2;
        border-radius: 5px;
        width: calc(100% - 42px);
        font-family: Visuelt-Regular;
        font-size: 16px;
        line-height: 25px;
        padding: 15px 20px;
      }

      .errorMessage {
        background: #FF6060;
        color: #fff;
        font-family: Visuelt-Medium;
        padding: 15px;
        border-radius: 5px;
        margin-bottom: 25px;
        margin-top: 15px;
      }

      textarea {
        background: #FFFFFF;
        border: 1px solid #E2E2E2;
        border-radius: 5px;
        resize: none;
        width: calc(100% - 42px);
        height: 150px;
        font-family: Visuelt-Regular;
        font-size: 16px;
        line-height: 25px;
        padding: 20px 20px;
      }
    }

    h2 {
      font-family: Visuelt-Medium;
      font-size: 26px;
      color: #000000;
      text-align: center;

      @media (max-width: 600px) {
        font-size: 22px;
      }
    }

    p {
      font-family: Visuelt-Regular;
      font-size: 16px;
      color: #8C8C8C;
      text-align: center;
      margin: 0 auto;
      line-height: 25px;
      max-width: 600px;
      margin-top: 20px;
    }

    .links {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 600px;
      width: calc(100%);
      margin: 50px auto;

      @media (max-width: 540px) {
        display: block;
        margin: 30px auto;
      }
      
      a {
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
        border-radius: 5px;
        max-width: 300px;
        width: calc(50% - 10px);
        height: 110px;
        display: block;
        position: relative;

        &:hover {
          background: #f5f5f5;
          cursor: pointer;
        }

        @media (max-width: 540px) {
          display: block;
          width: 100%;
          margin: 20px auto;
        }

        img {
          max-width: 180px;
          max-height: 35px;
          width: calc(100% - 40px);
          width: auto;
          height: auto;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }

    .charityBox {
      background: #FFFAD2;
      border-radius: 5px;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 560px;
      width: calc(100% - 40px);
      margin: 0 auto;

      @media (max-width: 540px) {
        display: block;
      }

      p {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000000;
        line-height: 25px;
        width: calc(100% - 150px - 10px);
        margin: 0;
        text-align: left;
        
        @media (max-width: 540px) {
          width: calc(100%);
          margin-bottom: 20px;
        }
      }

      img {
        width: 150px;
      }
    }
  }

  .submit {
    display: block;
    font-family: Visuelt-Medium;
    font-size: 18px;
    color: #fff;
    text-align: center;
    background: #243A58;
    max-width: 200px;
    width: 100%;
    border: 0;
    padding: 13px 0;
    margin-top: 10px;
    cursor: pointer;
    height: 50px;
    border-radius: 3px;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    &:hover {
      background: #1a2b42;
    }
  }

`;


const Hvezdicky = ({currectScore, setCurrentScore}) => {
  return(
    <div className="hvezdicky">
      <button onClick={(e) => setCurrentScore(e, 1)}>
        <img className={(currectScore >= 1) ? "isActive" : ""} src={HvezdaImg} alt="" />
      </button>
      <button onClick={(e) => setCurrentScore(e, 2)}>
        <img className={(currectScore >= 2) ? "isActive" : ""} src={HvezdaImg} alt="" />
      </button>
      <button onClick={(e) => setCurrentScore(e, 3)}>
        <img className={(currectScore >= 3) ? "isActive" : ""} src={HvezdaImg} alt="" />
      </button>
      <button onClick={(e) => setCurrentScore(e, 4)}>
        <img className={(currectScore >= 4) ? "isActive" : ""} src={HvezdaImg} alt="" />
      </button>
      <button onClick={(e) => setCurrentScore(e, 5)}>
        <img className={(currectScore >= 5) ? "isActive" : ""} src={HvezdaImg} alt="" />
      </button>
    </div>
  );
}


const ZpetnaVazba = ({data}) => {
  const [currectScore, setCurrentScore] = useState(0);
  const [faze, setFaze] = useState(0);
  const [errorHvezdy, setErrorHvezdy] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, errors, control } = useForm(); //watch
  

  const setCurrentScoreFunc = (e, num) => {
    e.preventDefault();
    setCurrentScore(num);
  }


	let backendUrl = "";

	if (process.env.NODE_ENV === 'development') {
		backendUrl = 'http://localhost:1337'
	}
	
	if (process.env.NODE_ENV === 'production') {
		backendUrl = 'https://goodbye-backend.herokuapp.com'
	}


  const onSubmit = data => handleForm(data);

  const handleForm = ({jmeno, recenze, odkud}) => {
    setIsLoading(true);

    if (currectScore === 0) {
      setIsLoading(false);
      setErrorHvezdy(true);
    }

    else {
      setErrorHvezdy(false);

      axios.post(`${backendUrl}/auth/local`, {
				identifier: 'app@goodbye.cz',
				password: 'Good.Bye123',
			}).then((res) => {
        const jwt = res.data.jwt;

        axios.post(`${backendUrl}/poslimail`, {
          "to": 'podpora@goodbye.cz',
          "from": 'podpora@goodbye.cz',
          "subject": `Nová zpětná vazba: ${jmeno}`,
          "text": `<h1>Nová zpětná vazba</h1><br/><p>Hvězdičky: ${currectScore}</p><br/><p>Jméno: ${jmeno}</p><br/><p>Recenze: ${recenze}</p><br/><p>Kde se o nás dozvěděli: ${odkud}</p>`,
          "html": `<h1>Nová zpětná vazba</h1><br/><p>Hvězdičky: ${currectScore}</p><br/><p>Jméno: ${jmeno}</p><br/><p>Recenze: ${recenze}</p><br/><p>Kde se o nás dozvěděli: ${odkud}</p>`,
        },
        {
          headers: {
            "Authorization": `Bearer ${jwt}`,
            "Content-Type": "application/json"
          },
        }).then(res => {
          setIsLoading(false);

          if (currectScore === 5) {
            setFaze(2);
          }

          else {
            setFaze(1);
          }
        });
      });
    }
  }

  useEffect(() => {
  
  }, []);


  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });


  return (
    <Layout>
      <SEO
        title="Zpětná vazba"
        noindex
        description="Moc si ceníme toho, že jste si vybrali právě naší společnost. Doufáme, že vše proběhlo bez problémů. Chtěli bychom vás poprosit o krátkou zpětnou vazbu, abychom mohli i do budoucna dále zlepšovat naše služby."
      />

      <ZpetnaVazbaInner>

        <div className="topSection">
          <h1>Děkujeme za důvěru</h1>
          <p>Moc si ceníme toho, že jste si vybrali právě naší společnost. Doufáme, že vše proběhlo bez problémů. Chtěli bychom vás poprosit o krátkou zpětnou vazbu, abychom mohli i do budoucna dále zlepšovat naše služby.</p>
        </div>

        <div className="whiteBox">

          {(faze === 0) && 
            <form onSubmit={handleSubmit(onSubmit)}>
              <label className={errors.jmeno && "inputError"}>
                <span>Vaše jméno (nepovinné)</span>
                <input placeholder="Nepovinné" ref={register()} type="text" name="jmeno"/>
              </label>

              <label className={errors.odkud && "inputError"}>
                <span>Jak jste se o nás dozvěděli?</span>
                <input placeholder="Google, doporučení známého, billboard..." ref={register()} type="text" name="odkud"/>
              </label>

              <label>
                <span>Jak byste ohodnotili naši společnost?</span>
                <p>1 - nejhorší, 5 - nejlepší</p>
                <Hvezdicky 
                  currectScore={currectScore}
                  setCurrentScore={setCurrentScoreFunc}
                />
                {errorHvezdy && <div className="errorMessage">Toto pole je povinné</div>}
              </label>

              <label className={errors.recenze && "inputError"}>
                <span>Recenze</span>
                <textarea placeholder="Jaká byla vaše zkušenost s naší společností?" ref={register()} name="recenze" />
              </label>
              <input className="submit" type="submit" value={(isLoading) ? "Načítání..." : "Pokračovat"} />
            </form>
          }

          {(faze === 1) &&
            <h2>Děkujeme za zpětnou vazbu</h2>
          }

          {(faze === 2) && <>
            <h2>Těší nás, že jste byli spokojeni</h2>
            <p>Pokud budete mít zájem, moc by nám pomohlo, kdybyste vaši recenzi publikovali i na stránkách Google Maps nebo Firmy.cz</p>
            
            <div className="links">
              <a target="_blank" href="https://g.page/r/CYMixNkSVNBWEBM/review">
                <img src={MapsImg} alt="" />
              </a>

              <a target="_blank" href="https://www.firmy.cz/detail/13491185-empaticka-pohrebni-sluzba-goodbye-cz-praha-nove-mesto.html">
                <img src={FirmyImg} alt="" />
              </a>              
            </div>

            <div className="charityBox">
              <p>Za každou recenzi darujeme 50 Kč organizaci Život dětem na pomoc nemocných, handicapovaných a opuštěných dětí v rámci celé České republiky</p>
              <img src={DetiImg} alt="Život dětem logo" />
            </div>
          </>}

        </div>

      
        {(isLess899) ? 
            <Img className="pozadi" alt="" fluid={data.small.childImageSharp.fluid} />
          :
            <Img className="pozadi" alt="" fluid={data.big.childImageSharp.fluid} />
        }
      </ZpetnaVazbaInner>
    </Layout>
  )
}


export const query = graphql`
  query {

    big: file(relativePath: { eq: "pohrebPozadi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    small: file(relativePath: { eq: "pohrebPozadiSmall.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default ZpetnaVazba